:root {
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em;  /* must NOT be a percentage value */
    
     /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    
     /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --base-font-family: 'Merriweather';
    --alternative-font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    
    /* change settings for headings */
    --header-bg-color: white;
    --header-padding: 1.5em 0 0;
    
    /* change colors for topmenu */
    --topmenu-bg: var(--link-color);
    --topmenu-color: white;
    --topmenu-li-a-padding: 10px 15px;

    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 14px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    
    /*Colors & Backgrounds*/
    --text-color: #444;
    --heading-color: var(--link-color);
    --link-color: #222;
    --link-hover-color: #000;
    --alternative-color: #8C1420;
    --f-color: #fff;
    --map-marker-color: 000000;
}

@import "/css/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/css/basic_styling.css";
@import "/css/flex_grid.v1.1.css";
@import "/css/slick.css";
@import "/css/slick-theme.css";
@import "/css/menu.v1.2.css";
@import "/css/magnific-popup.css";
@import "/fonts/Open Sans/all.css"; /* 300, 300, 300i, 300i, 400, 400, 400, 400i, 400i, 400i, 700, 700i */
@import "/fonts/Merriweather/all.css"; /* 300, 300i, 400, 400i, 700, 700i, 900, 900i */
/*@import "/cssmodules/animate.css";*/


/* General bg-colors
---------------------------------------------------------*/

.maincolor {
    background-color: var(--link-color);
}

.white {
    background-color:white;
}

.lightgray {
    background-color:#eee;
}

.darkgray {
    background-color:#222;
}

.f-color {
    color:var(--f-color);
}


/* Header
---------------------------------------------------------*/
 header {
    background:transparent;
    text-transform:none;
    padding:var(--header-padding);
    /*box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);*/
    padding:0.5em 0;  
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 1000;
}

header .topout {
    padding:0.5em 2em;
}

header .container {
    padding:0 0.5em;
}

header a {
    color:black;
}

.menu li {
    margin:0;
}

.menu li a {
    padding:var(--topmenu-li-a-padding);
    color:#ccd9e0;
    font-family: var(--alternative-font-family);
}

.menu li a:hover, .menu .active a {
    background: transparent;
    color:white;
}

.menu .dropdown ul {
    background:var(--topmenu-bg);
    z-index:100;
}

.menu li:hover ul {
    margin-left:0;
}

.menu .dropdown li a {
    padding:var(--topmenu-li-a-padding);
    color:#000;
    border-right:none;
}

.menu .dropdown li a:hover, .menu .dropdown .active a {
    background:var(--topmenu-bg);
    color:var(--topmenu-color);
}

.menu .sub-menu-items li a {
    margin:0;
}

.logo {
    padding:0;
}

.logo a {
    font-size:0;
}


/* bildspel
---------------------------------------------------------*/

.slick {
    position: relative;
    margin-bottom: 0;
}

.slick-track {
    display: flex;
    flex-direction: row;
}   

.homepage .slick,
.homepage .slick-slide,
.homepage .slick-substitute > div {
    height:100%;
    height: 50vh;
}

.homepage .slick-slide,
.homepage .slick-substitute > div {
    background-size: cover;
    background-position: center center;
}

.slick-slider {
    margin:0 auto;
    position: relative;
}

.homepage .slick-slide,
.homepage .slick-substitute > div {
    background-size:cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
} 

.slick-title {
    max-width:680px;
    width:100%;
    margin:0 auto;
    text-align:center;
    color:white;
    font-size: calc(1 * var(--scale-factor-s)rem);
}

.slick-title h2 {
    display:block;
    font-size: calc(1.7 * var(--scale-factor) * var(--scale-factor)rem);
    color:white;
    font-weight:700;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    margin-bottom:0;
    text-transform:uppercase;

}

.slick-title .button {
    background:rgba(34, 34, 34, 0.4);
    border:none
}

.slick-title .button:hover {
    border:none;
}

.slick-prev, .slick-next {
    width:auto;
    height:auto;
}

.slick-next {
    right:50px;
    z-index:100;
}

.slick-prev {
    left:50px;
    z-index:100;
}

.slick-next, .slick-prev {
    opacity:1;
}

.slick-next:hover, .slick-prev:hover {
    border:none;
}

.slick-next:before, .slick-prev:before {
    transition: 0.8s ease;
    -o-transition: 0.8s ease;
    -webkit-transition: 0.8s ease;
   background:rgba(0,0,0,0.3);
    padding:1.5em;
    font-family: Roboto;
    line-height:normal;
}

.slick-next:hover:before, .slick-prev:hover:before {
    background:white;
    color:black;
}

.slick-next:before {
    content:"►";
}

.slick-prev:before {
    content:"◄";
}

.slick-dots {
    bottom:0px;
}

.slick-dots li button:before {
    font-size:0rem;
    opacity:1.0;
    color: var(--alternative-color);
    width:40px;
    height:8px;
}

.slick-dots li.slick-active button:before {
    background:var(--alternative-color);
    display:block;
}

.slick-dots li {
    border:var(--alternative-color) solid 1px;
    border-radius:0%;
    margin:0 2px;
    width:40px;
    height:8px;
}

/* Content-startpage
---------------------------------------------------------*/
.block-2  {
    padding:2em 0;
}

.block-2 .container .inner-content {
    max-width:800px;
    margin:0 auto;
    text-align:center;
    border-bottom:#eee solid 1px;
}

.block-2 .container .inner-content h1 {
    font-family:Georgia;
    font-style:italic;
}

/* startboxar */
.startbox {
    margin-top:3em;
}

.startbox-wrapper {
    text-align:center;
}

.startbox-wrapper .pop-img svg.fa-icon {
    height:auto;
    width: 4em;
    fill: var(--alternative-color);
    vertical-align:middle !important;
}

.startbox-wrapper h2 {
    margin-top:1em;
}

.startbox-wrapper a {
    color:var(--link-color);
}

.startbox a {
    display:block;
    text-decoration:none;
}

.startbox a:hover {
    color:#6E667B;
}

.startbox-wrapper {
    padding:2em;
    border:1px solid #e8e8e8;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.startbox-wrapper:hover {
    border:1px solid #c3c3c3;
}

.pop-img img {
    display:block;
}

.readmore {
    margin-top:0.5em;
    border-top:#c3c3c3 solid 1px;
}
.readmore > div {
    padding-top:0.5em;
    color:#222;
}

.startbox-wrapper .readmore svg.fa-icon {
    height:auto;
    width: 1em;
    fill: var(--link-color);
    vertical-align:middle !important;
}
/* zoomboxar */

.boxwrapper {
    min-height: 25vh;
    background-size: 0% 0%;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}

.boxwrapper h2 {
    color: #fff;
    margin: 0;
    text-align:center;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    z-index: 3;
    font-weight: 400;
}

.zoombox .boxwrapper[style]:before, .boxwrapper:after, .boxwrapper figure img, .boxwrapper figure figcaption {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.zoombox .boxwrapper[style]:before {
    content: '';
    background-image: inherit;
    background-size: cover;
    background-position: center center;
    transition: transform .65s ease-in 0s;
}

.zoombox .boxwrapper figure img {
    transition: transform .65s ease-in 0s;
}

.zoombox .boxwrapper:after {
    content: '';
    background: rgba(0,0,0,0);
    transition: background .65s ease-in 0s;
    z-index: 2;
}

.zoombox .boxwrapper:hover:after {
    background: rgba(0,0,0,0.3);
    transition: background .65s ease-out 0s;
}

.zoombox .boxwrapper[style]:hover:before, .boxwrapper:hover figure img {
    transform: scale(1.3);
    transition: transform .65s ease-out 0s;
}

.zoombox .box-teaser {
    z-index:101;
    opacity:0;
    text-align:center;
    transition: 0.5s ease;
    color:white;
}

.zoombox .boxwrapper:hover .box-teaser {
    opacity:1;
}

.gallery-zoom .box-teaser {
    opacity:1;
    background:rgba(0,0,0,0.6);
    width:100%;
    min-height:50px;
    padding:0.5em 0;
}

.gallery-zoom .boxwrapper {
    box-shadow:0 0 0 2px #fff;
}


/* block-3 */
.block-3 {
    padding:1.5em 0;
}

/* listing items
---------------------------------------------------------*/
.listing-item {
    margin-bottom:1.5em;
}

.listing-item p:first-of-type {
    padding-top:0;
    margin-top:0;
}

.listing-item h2 {
    margin-bottom:0;
}

.pagination {
}

.pagination ul {
    float: left;
    clear: both;
    display: block;
    margin: 8px 0;
    padding: 0;
    border: 1px solid #DDD;
}

.pagination ul li {
    float: left;
    list-style-type: none;
    border-right: 1px solid #DDD;
    padding: 4px 6px;
}

.pagination ul li:last-child {
    border-right: none;
}

.pagination ul li.active {
    font-weight: bold;
    background-color: var(--link-color);
}

.pagination ul li.active a {
    color:white;
}

.pagination ul li a {
    text-decoration: none;
}
    
/* Content
---------------------------------------------------------*/

.content.margin > h1, .content.margin > .art-img {
    margin-left:0 ;
}

.content h1 {
    margin-bottom:0;
}

.art-content p:first-of-type {
    margin-top:0;
}

.top-img {
    background-size:cover;
    background-position: center center;
    height:100%;
    height: 50vh;
} 

.top-img .slick-overlay {
    height:100%;
    width:100%;
    z-index:1;
    display: flex !important;
    align-items: flex-end;
    justify-content: flex-start;
}

.top-img .img-alt {
    max-width:650px;
    width:100%;
    margin:0;
    position:relative;
    color:white;
    font-weight:500;
    text-transform:uppercase;
    font-size: calc(1 * var(--scale-factor)rem);
}


/* Contact
---------------------------------------------------------*/

body.kontakt {
    background:#f5f5f5;
}

.contact-block {
    box-shadow: 0px 0px 0px 1px #ececec;
}

.contact-block-l, .contact-block-2 {
    padding: var(--padding);
}

.contact-block-2 > div {
    border-bottom:#eee solid 1px;
    padding:1.5em 0;
}

.contact-block-2 .contactinfo p {
    margin:0;
}

.contact-block-2>div a {
    text-decoration:none;
}


figure {
    margin:0;
}

.component-item {
    margin-bottom:1em;
}

.component-item .stock {
    background-color:#e7e7e7;
}

.component-body {
    font-size:0.9rem;
}

.tab-menu {
    min-height:1.9em;
    border-bottom:#eee solid 1px;
}

.tab-menu a {
    text-decoration:none;
    background:#eee;
    padding:0.5em 1em;
    margin-left:0.5em;
}

.tab-menu a:hover {
    background:#222;
    color:white;
}

.tabbs {
    margin-top:1em;
}

/* Footer
---------------------------------------------------------*/

.album-bild {
    position:relative;
    margin: 5px;
}
.album-bild img {
    display:block;
 }
.info { 
    opacity:0;  
    position:absolute; 
    width:100%; 
    height:100%; 
    top:0; 
    left:0; 
    right:0; 
    bottom:0; 
    z-index:1; 
    padding: 10px; 
    font-size:0.9em; 
    line-height:1.4em; 
    text-align:right; 
    color:#e4e4e4;  
    background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.9) 100%); 
}
.info img { 
    display: inline-block;
    height:17px; 
    width:auto; 
    margin:0 0 0 5px; 
    vertical-align:top !important; 
    fill:#e4e4e4;
}
.album-bild:hover .info {
    opacity:1; 
}

/* Footer
---------------------------------------------------------*/



footer {
    color:#5f5f5f;
}

footer h4 {
    color:#b7b7b7;
    font-size:1.6rem;
    letter-spacing:0;
}

footer h5 {
    color:#b7b7b7;
    font-size:1.1rem;
    letter-spacing:0;
}


footer a {
    color:#5f5f5f;
    text-decoration:none;
}

footer a:hover {
    color:var(--alternative-color);
}

footer svg.fa-icon {
    fill:#5f5f5f;
    width:28px;
    height:28px;
}

footer svg.fa-icon:hover {
    fill: var(--alternative-color);
}

footer .button {
    margin:0;
    border:#b7b7b7 solid 1px;
    border-radius:0px;
    display:block;
    text-align:left;
}

footer .button:hover {
    background:var(--alternative-color);
    border:var(--alternative-color) solid 1px;
    color:white;
}


/*Google maps*/
.map-canvas {
    height: 30vh;
}

.map-canvas img {
    max-width: none;
}
 
/*Intendit footer*/
.created-by {
    background:#100F0F;
    border-top:#151414 solid 1px;
    padding:0.5em 0;
}

.created-by .container, .intenditfooter {
    padding:0;
    margin:0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a  {
    color:#565656;
}

.intenditfooter >* {
    margin: var(--margin);
}

/* sticky footer */
.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%; /* IE fix */
}

header, footer {
    flex: none;
}

footer .contactinfo p {
    margin:0;
}

/*Instagram album*/
.album-bild {
    position:relative;
    margin: 5px;
}
.album-bild img {
    display:block;
 }
.info { 
    opacity:0;  
    position:absolute; 
    width:100%; 
    height:100%; 
    top:0; 
    left:0; 
    right:0; 
    bottom:0; 
    z-index:1; 
    padding: 10px; 
    font-size:0.9em; 
    line-height:1.4em; 
    text-align:right; 
    color:#e4e4e4;  
    background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.9) 100%); 
}
.info img { 
    display: inline-block;
    height:17px; 
    width:auto; 
    margin:0 0 0 5px; 
    vertical-align:top !important; 
    fill:#e4e4e4;
}
.album-bild:hover .info {
    opacity:1; 
}



/* general
---------------------------------------------------------*/

.boltform textarea {
    min-height:120px;
}

.boltform label {
    display:none;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
    width: 100%;
    color:#222;
}

button[type=submit], input[type=submit], .button, button {
    margin:0;
    background:var(--link-color);
    border:var(--link-color) solid 1px;
    color:white;
}

button[type=submit]:hover, input[type=submit]:hover, .button:hover, button:hover {
    background: var(--link-hover-color);
    border:var(--link-hover-color) solid 1px;
    color:white;
}


img {
    max-width: 100%;
    height: auto;
}

.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
}

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    height:100%;
}

body {
    font-family: var(--base-font-family);
    font-weight: 400;
    color: var(--text-color);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

h1, h2, h3, h4, h5, h6 {
    letter-spacing:-1px;
    color: var(--heading-color);
    margin:0 0 0.3em 0;
}

h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
}

h3 {
    font-size: 1.2rem;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--alternative-font-family);
}

a {
    color: var(--link-color);
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

a:hover {
    color: var(--link-hover-color);
}

.markerLabels{
    overflow:visible !important;
}

.fa-map-marker{
    background: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22%23var(--map-marker-color)%22%20width%3D%221792%22%20height%3D%221792%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1152%20640q0-106-75-181t-181-75-181%2075-75%20181%2075%20181%20181%2075%20181-75%2075-181zm256%200q0%20109-33%20179l-364%20774q-16%2033-47.5%2052t-67.5%2019-67.5-19-46.5-52l-365-774q-33-70-33-179%200-212%20150-362t362-150%20362%20150%20150%20362z%22%2F%3E%3C%2Fsvg%3E');
    background-size: cover;
    padding: 10px;
    background-position: center;
}



/* Media Queries
---------------------------------------------------------*/
 
@media (max-width: var(--breakpoint-menu)) {
    .menu.show, .menu {
        background:#000 !important;
        box-shadow: 0 4px 2px -2px gray;
    }
    header .topout {
        padding:0.5em;
    }
    .homepage .slick, .homepage .slick-slide, .homepage .slick-substitute>div {
        min-height:calc(100vh - 80px);
    }
    .slick-title h2 {
        font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    }
    .container {
        padding:0.5em 2em;
    }
    .section-content .inner {
        padding:0;
    }
    #menu-icon:before, #menu-icon:after, #menu-icon span {
        border-color:#fff;
    }
    
}

@media (max-width: var(--breakpoint-s)) {
}

@media (max-width: var(--breakpoint-xs)) {
    footer .container {
        text-align:center;
    }
    .section-img {
        order:1;
    }
    .section-content {
        order:2;
    }
    .boxwrapper {
        margin-bottom:1em;
    }
      
}
@media (min-width: var(--breakpoint-menu)) {

    .responsive-video {
        height:324px;
    }
}

.compsection {
    padding:4em 0;
}

.section-content .inner {
    padding:0 2em;
}

.lightgray .inner {
    padding:0 2em 0 0;
}

.section-gallery img {
    display:block;
    box-shadow: 0 0 0 0.5em #eee;
    margin-bottom:0.5em;
}
.download {
    padding:1.5em;
}

.download ul {
    list-style:none;
    margin:0;
    padding:0;
}

.download ul li {
    padding:0.3em 0;
}

.kalender ul {
    list-style:none;
    margin:0;
    padding:0;
}
.kalender ul .inner {
    overflow: hidden;
    display: none;
}

.kalender a {
    text-decoration:none;
}

.kalender ul li {
  margin: .5em 0;
}

.kalender ul li a.toggle {
  width: 100%;
  display: block;
  background: rgba(0, 0, 0, 0.78);
  color: #fefefe;
  padding: .75em;
  border-radius: 0.15em;
  -webkit-transition: background .3s ease;
          transition: background .3s ease;
}

.kalender ul li a.toggle:hover {
  background: rgba(0, 0, 0, 0.9);
}

.kalender ul li:first-child ul {
    display:block;
}

.kalender .inner {
    margin:0;
    padding:0 0 0 1em;
}

.kalender p {
    margin:0.3em 0;
    border-bottom:#333 dotted 1px;
}

[data-tabname] {
    display: none;
}

[data-tabname].active {
    display: flex;
}

.slick:not(.slick-slider) > div:not(:first-child) {
    display: none;
}

.slick:not(.slick-slider) > div {
    height: 100%;
    min-height: 100vh;
}
